import React from 'react';
import colors from '../../../utils/colors';
import { CustomIconProps } from './types';

export const CalendarOneTime = ({
  size,
  color = colors.brand.gray.dark,
}: CustomIconProps) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 72 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="12.75"
        y="17.55"
        width="46.5"
        height="41.7"
        rx="3.25"
        stroke={color}
        stroke-width="1.5"
      />
      <path
        d="M12.75 19.4614C12.75 17.6665 14.2051 16.2114 16 16.2114H56C57.7949 16.2114 59.25 17.6665 59.25 19.4614V26.2499H12.75V19.4614Z"
        fill={color}
        stroke={color}
        stroke-width="1.5"
      />
      <rect
        x="21.6001"
        y="12"
        width="7.2"
        height="4.61538"
        rx="1"
        fill={color}
      />
      <rect x="43.2" y="12" width="7.2" height="4.61538" rx="1" fill={color} />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M31.3679 38.3679C33.9395 35.7963 38.0975 35.7602 40.7134 38.2867C43.2398 40.9026 43.2037 45.0606 40.6322 47.6322C38.0606 50.2037 33.9026 50.2398 31.2867 47.7134C28.7602 45.0975 28.7963 40.9395 31.3679 38.3679ZM39.0372 45.3667C39.0372 45.1889 38.9662 45.0185 38.84 44.8934L36.94 43L38.84 41.1067C39.0091 40.9376 39.0752 40.6911 39.0133 40.4601C38.9514 40.2291 38.771 40.0487 38.5399 39.9868C38.3089 39.9249 38.0625 39.9909 37.8934 40.16L36 42.06L34.1067 40.16C33.9376 39.9909 33.6911 39.9249 33.4601 39.9868C33.2291 40.0487 33.0487 40.2291 32.9868 40.4601C32.9249 40.6911 32.9909 40.9376 33.16 41.1067L35.06 43L33.16 44.8934C33.0338 45.0185 32.9628 45.1889 32.9628 45.3667C32.9628 45.5445 33.0338 45.7149 33.16 45.84C33.2858 45.9648 33.4562 46.0344 33.6334 46.0334C33.8106 46.0344 33.9809 45.9648 34.1067 45.84L36 43.94L37.8934 45.84C38.0192 45.9648 38.1895 46.0344 38.3667 46.0334C38.5439 46.0344 38.7142 45.9648 38.84 45.84C38.9662 45.7149 39.0372 45.5445 39.0372 45.3667Z"
        fill={color}
      />
    </svg>
  );
};
